<template>
  <section>
    <div class="showcase-content">
      <template v-if="services && services.length">
        <Services
          :services-list="services"
          :active-service="activeService"
          :is-services-list-loading="false"

          @selectService="changeActiveService"
        />

        <div
          class="refresh-icon"
          @click="changeActiveShowcase('countries')"
        >
          <img
            src="~/assets/img/showcase/refresh.svg"
            alt="refresh"
          >
        </div>
        <Countries
          v-if="countriesListForCountry"
          :countries-list="countriesListForCountry"
          :loading-countries-ids="loadingCountriesIds"

          @selectCountry="onByNumber"
        />
      </template>
      <div
        v-else
        class="lading-showcase"
      >
        {{ $t('loading') }}
      </div>
    </div>
  </section>
</template>

<script setup lang="ts">
import Countries from '~/components/home/showcase/showcase-services/countries/CountriesList.vue';
import Services from '~/components/home/showcase/showcase-services/services/ServicesList.vue';
import type { GetServicesApp } from '~/helpers/parsers/ServicesDTO';
import type { CountriesListWithPriceType } from '~/server/routes/api-front/prices/by-service/[service]';
import type { ServiceListWithPriceType } from '~/server/routes/api-front/prices/by-country/[country]';
import type { ServerResponseCommonType } from '~/types/commons';
import { useUserAuth } from '~/composables/user/useUserAuth';
import { useActivationService } from '~/composables/activation/useActivationService';
import { servicesStore } from '#imports';

const {
  $i18n,
  $toast
} = useNuxtApp();
const { currentUser } = useUserAuth();
const { getPhoneNumber } = useActivationService();
const { changeActiveShowcase } = useChangeShowcaseView();
const storeService = servicesStore();
const { serviceList } = storeToRefs(storeService);
const activeService = ref<GetServicesApp | null>(null);
const defaultServiceId = 27;

const services = computed(() => {
  return serviceList.value;
});

if (services.value) {
  activeService.value = (services.value.find((t) => t.id === defaultServiceId)) || null;
}

const {
  data: countriesListForCountry,
  refresh: getCountriesListForService,
  pending: isCountriesListForCountry
} = await useCustomFetch<CountriesListWithPriceType[]>(() => `/api-front/prices/by-service/${activeService.value?.sms_activate_org_code}`, {
  immediate: false,
  watch: false,
  baseURL: '/'
});

await getCountriesListForService();

async function changeActiveService (service: GetServicesApp) {
  activeService.value = service;
  await getCountriesListForService();
}

// устанавливаем лоадер
const loadingCountriesIds: Ref<number[]> = ref([]);
// дублируем массив и пушим в него id, который передаем из ребенка
const addIdForLoadingCountriesIds = (id: number) => {
  let _loadingCountriesIds: number[] = [...loadingCountriesIds.value];
  _loadingCountriesIds.push(id);
  loadingCountriesIds.value = _loadingCountriesIds;
}
// удаялем id элемента, на котором истекло время лоадера
const removeIdForloadingCountriesIds = (id: number) => {
  let _loadingCountriesIds: number[] = [...loadingCountriesIds.value];
  const findIndex = _loadingCountriesIds.findIndex((t) => Boolean(t === id));
  _loadingCountriesIds.splice(findIndex, 1);
  loadingCountriesIds.value = _loadingCountriesIds;
}

async function onByNumber (country: CountriesListWithPriceType) {
  if (currentUser) {
    // Устанавливаем событие загрузки для кнопки по ID страны
    addIdForLoadingCountriesIds(country?.id);
    const res = await getPhoneNumber({
      service: activeService.value!.id,
      multiple_sms: 0,
      method: 'phone',
      api_key: currentUser.api_token,
      country: country.id
    }).catch((err: ServerResponseCommonType) => {
      $toast.error(err.message);
      removeIdForloadingCountriesIds(country?.id);
      return null;
    });

    if (res) {
      $toast.success($i18n.t('toast.buyNumberSuccess'))
      removeIdForloadingCountriesIds(country?.id);
      navigateTo('/cabinet');
    }

    return;
  }
  navigateTo('/authorization');
}
</script>

<style scoped lang="scss">
.showcase-content {
	display: flex;
	gap: 4px;
	max-height: 660px;
	height: 660px;
	overflow: hidden;
	position: relative;

	& > section {
		flex: 1;
	}
  @media screen and (max-width: 1024px) {
    gap: 50px;
    > section {
      border-radius: 0;
    }
  }
}
@media screen and (max-width: 991px) {
  .showcase-content {
    flex-direction: column;
  }
}
</style>
